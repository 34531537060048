// import Vue from 'vue'
// import { Toast } from '@shein/sui-mobile'
// import { quickAdd } from 'public/src/pages/components/product/quick_add_v2/index'
// import analysisDirective from 'public/src/pages/common/analysis/directive.js'
// import 'public/src/pages/common/libs_env_injection.js'
// import 'public/src/pages/common/sw.js'
// import 'public/src/pages/common/shein_common/core'
// import 'public/src/pages/common/variable'
// import '../../services/TPM'
// import 'public/src/pages/common/scroll_top'
import 'public/src/pages/common/cookie_id'
import 'public/src/services/abt'
// import 'public/src/pages/common/devices'
// import 'public/src/pages/common/performance'
// import schttp from '../../services/schttp'
// import { loadGlobalPopModule } from 'public/src/pages/common/glob_popup_modal'
// import { template } from '@shein/common-function'
// import infiniteScroll from '../../vendor/vue_infinte_scroll'

// const { NODE_SERVER_ENV } = gbCommonInfo

// window.__webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`

// Vue.config.performance = NODE_SERVER_ENV !== 'production'
gbCommonInfo.user = window.gbCheckoutSsrData.user || {}

// Vue.prototype.$toast = Toast
// Vue.prototype.$quickAdd = quickAdd
// Vue.use(analysisDirective)
// Vue.use(infiniteScroll)

// Vue.component(Drawer.name, Drawer)
// window._GB_LoginEventCenter_ = new Vue()

appEventCenter.on('pageOnload', () => {
  try {
    if(window.SaPageInfo) {
      sa('set', 'setPageData', window.SaPageInfo)
    }
    sa('send', 'pageEnter', { start_time: Date.now() })
  } catch (e) { console.log(e) }
})
// Vue.mixin({
//   methods: {
//     template: template
//   }
// })

// window.addEventListener('load', () => {
//   requestIdleCallback(() => {
//     // none.css 统计
//     appEventCenter.on('pageOnload', () => {
//       asyncLoadFile({
//         label: 'script',
//         attrs: { src: `//count.${WEB_CLIENT}.com/none.css?server=${SERVER_TYPE}` }
//       })
//     })
//   })
// })

// loadGlobalPopModule()

// $(() => {
//   import('public/src/pages/common/analysis/declarative').then(({ default: DeclarativeAnalysis }) => {
//     const declarativeAnalysis = new DeclarativeAnalysis()
//     declarativeAnalysis.init()
//   })

//   requestIdleCallback(() => {
//     //更新csrf_token
//     schttp.updateXCsrfToken()
//     // init fastclick
//     // eslint-disable-next-line no-undef
//     FastClick.attach(document.body)
//     // eslint-disable-next-line no-undef
//     FastClick.prototype.focus = function (targetElement) {
//       targetElement.focus()
//     }

//     import('../login/asyncIndex')
//     import('../components/globalPrivacy/asyncIndex')

//   })
// })
